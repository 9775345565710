import React, {useState} from "react";
import {useSortableData,getClassNamesFor} from "../sortable/sort";
import "./style.css";

export const EtTable = (props) =>{

    const { items, requestSort, sortConfig } = useSortableData(props.data);
    const displayFieldKeys = props.displayFieldKeys;
    const headers = props.headers;
    const [selectedPage, setSelectedPage] = useState(1);

    const pageLimit = props.limit?props.limit:10;

    const pageLength = Math.ceil(props.data.length / pageLimit);
    const pageLengthArr = new Array(pageLength).fill(false);

    const productView = props.productView;

    return(
        <div className="card card-body table-responsive" style={{padding:0}}>
        <table className="table summaryTable" style={{marginBottom:props.origin === "status" ? 0:"1rem"}}>
        <thead>
            <tr>
               {generateTableHeader(headers,sortConfig,requestSort,props.sortable)}
            </tr>
        </thead>
        <tbody>
            {generateTableContent(items, displayFieldKeys,selectedPage,props.showPagination,pageLimit)}
            {props.origin === "status" && <tr className='totalSummary' style={{marginTop:5,borderTop:"solid 2px black",borderBottom:"solid 2px black"}}>
                  <td></td>
                  {/* <td></td> */}
                  <td></td>
                  {/* <td className="boldText">RM {props.statusProps.marketValue}</td> */}
                  {/* {productView != "Digital Asset Tracker" ? <td></td> : null} */}
                  <td></td>
                  <td className="boldText">{props.statusProps.percentagePortfolio} %</td>
            </tr>}
        </tbody>
    </table>
    {props.data.length === 0 && <p className="text-center" style={{fontStyle:"italic"}}>NO RECORDS FOUND</p>}

    {props.showPagination && props.data.length > pageLimit && <nav aria-label="Page navigation example">
        <ul className="pagination tablePagination">
            <li className={selectedPage === 1 ?"page-item disabled":"page-item"} onClick={()=>{
                selectedPage !== 1 && setSelectedPage(selectedPage-1);
            }}><span className="page-link tablePageBtn">Previous</span></li>
               {
                  pageLengthArr.map((item,index)=>{
                      return(
                        <li className={selectedPage===index+1?"page-item active":"page-item"} key={index}><span className="page-link tablePageBtn" onClick={()=>{
                            setSelectedPage(index+1);
                        }}>{index+1}</span></li>
                      )
                  })
               }
            <li className={selectedPage === pageLength ?"page-item disabled":"page-item"}
                onClick={()=>{
                    selectedPage !== pageLength && setSelectedPage(selectedPage+1);
                }}
            ><span className="page-link tablePageBtn">Next</span></li>
        </ul>
    </nav>}
    </div>
    );
}

function generateTableHeader(headers,sortConfig,requestSort,sortable){
    return headers.map((item,index)=>{
        return(
            <th 
            key={index}
            onClick={() => sortable && item.sort && requestSort(item.sort)}
            className={getClassNamesFor(item.sort,sortConfig)}
            style={item.style}
            >{item.display}</th>
        )
    });
}

function generateTableContent(items, displayFieldKeys, selectedPage,showPagination,pageLimit){
    let rangeStart = (selectedPage - 1) * pageLimit;
    let rangeEnd = Math.min(rangeStart + pageLimit - 1, items.length - 1);
    return items.map((item,index)=>{
        let mapped = displayFieldKeys.map((innerItem,index)=>{
            return(
                <td key={index} style={{color:innerItem.styleKey?item[innerItem.styleKey]:"black",...innerItem.style}}
                    className={innerItem.isClickable?"cursorHover":""}
                    onClick={()=>{
                        if(innerItem.isClickable){
                            innerItem.clickAction(item[innerItem.clickProp.token]);
                        }
                    }}
                >
                    {innerItem.prepend}{item[innerItem.key]}{innerItem.append}
                </td>
            )
        })
        return(
            <tr key={index}>
                {mapped}
            </tr>
        )
    }).filter((item,index)=>{
        if(showPagination){
            return index >= rangeStart && index <= rangeEnd
        }else{
            return item;
        }
    });
}